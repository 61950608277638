
import {  useState , useEffect, useRef} from 'react'
import axios from 'axios'
import { useMask } from '@react-input/mask';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import Modal from 'react-modal';
import { TbMailFast } from "react-icons/tb";
import Footer from '../components/Footer'


import levelup_logo_main from '../assets/images/levelup_logo_main.png'



function ForgotPasswordSent() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  
  const handleEmail = (e) => { setEmail(e.target.value) }

  const goToHome = () => {
    navigate('/')
  }


  



    return (
      <>
      <div className='web_bg min-h-screen'>
          
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>

            <div className='pt-4'><img onClick={goToHome} src={levelup_logo_main} className='w-40 cursor-pointer' alt='' /></div>

            <div className='mt-10 place-items-center flex flex-col'>
              <TbMailFast size={100} strokeWidth={1} />
              <div className='text-center  mt-2'>Şifreni sıfırlayabilmen için email adresine bir link gönderdik.</div>
              <div className='text-center text-yellow-100  mt-4'>Her ihtimale karşı spam (junk) klasörünü de kontrol et.</div>
            </div>



            

          </div>



      </div>
      
      </>
    );
}

  export default ForgotPasswordSent;