
import {  useState , useEffect, useRef} from 'react'
import axios from 'axios'

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import levelup_panel_logo from'../assets/images/levelup_panel_logo.png';




function AdminLogin() {
  const navigate = useNavigate();
  const [islogin, setIslogin] = useState(false);
  
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const handleEmail = (e) => {
      setEmail(e.target.value)
  }

  const handlePass = (e) => {
    setPass(e.target.value)
  }

  useEffect(() => {},[]);



  const validateEmail = (em) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(em);
  }


  const login = () => {

    let _ready = true

    if(pass.length <= 7){_ready = false; showError("Lütfen şifrenizi giriniz");}
    if(!validateEmail(email)){_ready = false; showError("Lütfen email adresinizi giriniz");}

    if(_ready){

        let data = {
          member_email : email,
          member_password : pass,
        };
        axios.post(process.env.REACT_APP_API_URL+'admin_login',JSON.stringify(data))
        .then(function (response) {

          console.log(response.data)
              
              if(response.data.result === 'Success'){
    
                  localStorage.setItem('otesi_admin_token', response.data.token);
                  navigate("/admin_otesi/dashboard")
    
              }else{
                showError("Şifreniz veya email adresiniz yanlış!");
              }
              

        }).catch((error) => {
          console.log(error);
          if(error.response.status === 403){
            showError("Şifreniz veya email adresiniz yanlış!");
          }else{
            showError("Bir hata oluştu, tekrar deneyin!");
          }
          
          
        })
    
    
    }
  }

  const goToGiris = () => {
    navigate('/giris')
  }

  const goToDashboard = () => {
    navigate('/hosgeldiniz')
  }



    return (
      <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-10">
          <img className="mx-auto w-14 " src={levelup_panel_logo} alt='' />
          <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Ötesine Geç Yönetim Paneli</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div>
              <label htmlFor="email" className="block font-medium leading-6 ">Email Adresi</label>
              <div className="mt-2">
                <input type="email" placeholder='Email adresi' value={email} onChange={handleEmail}
                  className="block w-full rounded-md border-0 p-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  "  />
              </div>
            </div>

            <div className='mt-4'>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block font-medium leading-6 text-gray-900">Şifre</label>
              </div>
              <div className="mt-2">
                <input type="password" placeholder='******' value={pass} onChange={handlePass}
                  className="block w-full rounded-md border-0 p-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  "/>
              </div>
            </div>

            <div className='mt-6'>
              <button onClick={login}
              className="flex w-full justify-center rounded-md bg-slate-800 px-3 py-4  font-semibold leading-6 text-white shadow-sm hover:bg-slate-900 " >
                Giriş
              </button>
            </div>

          
        </div>
      </div>


      </>
    );
}

  export default AdminLogin;