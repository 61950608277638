
import {  useState , useEffect, useRef} from 'react'
import levelup_logo_footer from '../assets/images/levelup_logo_footer.png'
import sosyal_tiktok from '../assets/images/sosyal_tiktok.png'
import sosyal_youtube from '../assets/images/sosyal_youtube.png'
import sosyal_x from '../assets/images/sosyal_x.png'
import sosyal_instagram from '../assets/images/sosyal_instagram.png'
import sosyal_email from '../assets/images/sosyal_email.png'
import Modal from 'react-modal';
import {  TbX } from "react-icons/tb";
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '60vh',
        
    },
};

function Footer() {
    const navigate = useNavigate();


    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [modal3Open, setModal3Open] = useState(false);

    function openModal1() { setModal1Open(true); }
    function closeModal1() { setModal1Open(false); }
    function openModal2() { setModal2Open(true); }
    function closeModal2() { setModal2Open(false); }
    function openModal3() { setModal3Open(true); }
    function closeModal3() { setModal3Open(false); }

    return (
        <>
        <div className='py-8 mt-28 flex flex-col lg:flex-row space-y-4 items-center space-x-4'>

            <div className='flex-1 flex items-center space-x-4'>
                <div><img  src={levelup_logo_footer} className='w-10 mr-4' alt='' /></div>
                <div><a href='https://www.tiktok.com/@otesine_gec'  target='_blank'><img  src={sosyal_tiktok} className='w-8' alt='' /></a></div>
                <div><a href='https://twitter.com/otesine_gec'  target='_blank'><img  src={sosyal_x} className='w-8' alt='' /></a></div>
                <div><a href='https://www.instagram.com/otesine_gec/' target='_blank' ><img  src={sosyal_instagram} className='w-8 cursor-pointer' alt='' /></a></div>
                <div><a href='mailto:otesinegec@outlook.com'><img  src={sosyal_email} className='w-8' alt='' /></a></div>
            </div>
            <div><button onClick={openModal1} className='hover:text-pink-400' >Kullanım Koşulları</button></div>
            <div><button onClick={openModal3} className='hover:text-pink-400' >KVKK Bildirimi</button></div>
            <div><button onClick={openModal2} className='hover:text-pink-400' >KVKK Açık Rıza Beyanı</button></div>
        </div>

        <Modal isOpen={modal1Open} onRequestClose={closeModal1} style={customStyles} >
            <div className='flex justify-end'><button onClick={closeModal1}><TbX strokeWidth={2.7} size={22} /></button></div>
            <div className='text-2xl font-medium'>Otesinegec.com Kullanım Koşulları</div>
            <div className='mt-2'>
            <div className='mt-2'>1. Kabul Edilme: Bu web sitesini ziyaret ederek, aşağıdaki kullanım koşullarını
            kabul etmiş olursunuz. Lütfen bu koşulları dikkatlice okuyun.</div>

            <div className='mt-2'>2. İçerik Kullanımı: Otesinegec.com, Level UP etkinliği hakkında bilgi sağlayan
            bir web sitesidir. Sitede bulunan her türlü içerik (metinler, fotoğraflar, videolar
            vb.) sadece bilgilendirme amacıyla sunulmuştur. Bu içeriklerin ticari amaçlarla
            kullanılması kesinlikle yasaktır.</div>

            <div className='mt-2'>3. Fikri Mülkiyet Hakları: Otesinegec.com üzerinde bulunan tüm içerikler,
            şirketimize veya lisans verenlerimize aittir ve uluslararası fikri mülkiyet
            kanunlarıyla korunmaktadır. İzinsiz olarak kopyalanması, çoğaltılması veya
            dağıtılması yasaktır.</div>

            <div className='mt-2'>4. Kullanıcı Katkıları: Otesinegec.com üzerinde kullanıcılar tarafından sağlanan
            fotoğraf, video ve diğer içerikler, kullanıcıların sorumluluğundadır. Bu
            içeriklerin herhangi bir şekilde yasa dışı, uygunsuz veya zararlı olması
            durumunda, şirketimiz bunları kaldırma veya değiştirme hakkına sahiptir.</div>

            <div className='mt-2'>5. Üçüncü Taraf Bağlantıları: Otesinegec.com, üçüncü taraf web sitelerine
            bağlantılar içerebilir. Bu bağlantılar sadece bilgi amaçlıdır ve bu web sitelerinin
            içeriği veya politikalarıyla ilgili herhangi bir sorumluluk kabul etmiyoruz.</div>

            <div className='mt-2'>6. Gizlilik Politikası: Web sitemizi kullanarak, gizlilik politikamızı kabul etmiş
            olursunuz. Lütfen gizlilik politikamızı dikkatlice okuyun.</div>

            <div className='mt-2'>7. Değişiklikler: Otesinegec.com, bu kullanım koşullarını dilediği zaman
            değiştirme hakkını saklı tutar. Değişiklikler web sitesinde yayınlandıktan sonra
            geçerli olacaktır. Lütfen düzenli olarak bu sayfayı kontrol edin.</div>

            <div className='mt-2'>8. İletişim: Herhangi bir soru, öneri veya şikayetiniz varsa, lütfen bizimle iletişime
            geçmekten çekinmeyin.</div>

            <div className='mt-2'>Bu kullanım koşullarını kabul etmediğiniz takdirde, lütfen web sitemizi kullanmayın.
            Kullanım koşulları kabul edilmediği takdirde doğacak sorumluluk tarafınıza ait
            olacaktır. Teşekkür ederiz.</div>

            </div>
        </Modal>
        <Modal isOpen={modal2Open} onRequestClose={closeModal2} style={customStyles} >
            <div className='flex justify-end'><button onClick={closeModal2}><TbX strokeWidth={2.7} size={22} /></button></div>
            <div className='text-2xl font-medium'>KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AÇIK RIZA BEYANI</div>
            <div className='mt-2'>
                <div className='mt-2'>6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVK Kanunu”) ilgili hükümlerine uygun olarak bilginize sunulan Kişisel Verilerin Korunması Kanunu Kapsamında Genel Aydınlatma Metni ve Şirket Kişisel Verilerin Korunması ve İşlenmesi Yönetmeliği çerçevesinde,</div>
                <div className='mt-2'>Kişisel verilerinin veri sorumlusu sıfatıyla Şirket veya gerekli güvenlik tedbirlerini aldırmak suretiyle yetkilendirdiği veri işleyenler tarafından; Şirket’in müşterilerine sunmuş olduğu ürün ve hizmetlerini en iyi koşullar altında sağlayabilmesi, ürün veya hizmetlerin güvenilir ve kesintisiz bir şekilde temin edilmesi, müşteri memnuniyetinin en üst seviyeye çıkarılması, ödemelerin yapılması, mezkûr hizmetlere ilişkin çeşitli işlemlerin yerine getirilmesi, operasyonların yürütülmesi ve geliştirilmesi, mezkûr ürün ve hizmetlerin veya farklı ürün ve hizmetlerin tanıtım, pazarlama, reklam ve kampanya faaliyetlerinin yapılması, müşterilerin fırsatlardan, kampanyalardan ve sair hizmetlerden haberdar edilmesi ve müşterilerle akdedilen sözleşmelerin gereklerinin yerine getirilmesi amaçlarıyla doğrudan veya dolaylı olarak ilgili olan kimlik bilgilerinin, adres bilgilerinin, iletişim bilgilerinin ve sair kişisel verilerin; başta mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme ilkesi olmak üzere 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVK Kanunu”) 4. maddesinde ifade edilen genel ilkelere uygun şekilde işlenebileceğini; elde edilebileceğini, kaydedilebileceğini, işlenme amacıyla uygun süre zarfında fiziksel veya elektronik ortamda güvenli bir şekilde depolanabileceğini, muhafaza edilebileceğini, değiştirilebileceğini, yeniden düzenlenebileceğini, mevzuata uygun biçimde açıklanabileceğini ve aktarılabileceğini, devralınabileceğini, sınıflandırılabileceğini, işlenebileceğini ya da verilerin kullanılmasının engellenebileceğini; yukarıda belirtilen hususlarla ilgili olarak Şirket tarafından bilgilendirildiğimi ve KVK Kanunu çerçevesinde açık rızam bulunduğunu kabul ve beyan ederim.</div>
                <div className='mt-2'>İşbu kişisel verilerimin, yukarıda belirtilen amaçlarla bağlı kalmak kaydıyla, Şirket tarafından kanunen yetkili kamu kurum ve kuruluşlarına, faaliyetlerini yürütebilmek amacıyla, hukuki zorunluluklar ve yasal sınırlamalar çerçevesinde bağımsız denetim şirketlerine, Şirket’in hizmet aldığı veya birlikte çalıştığı iş ortaklarına ve hizmet sağlayıcılarına aktarılabileceğini ve bu hususta açık rızam olduğunu kabul ve beyan ederim.</div>
                <div className='mt-2'>Bununla birlikte, KVK Kanunu’nun 11.maddesi ve ilgili mevzuat uyarınca; Şirkete başvurarak kendimle ilgili; kişisel verilerimin işlenip işlenmediğini öğrenme, kişisel verilerim işlenmişse buna ilişkin bilgi talep etme, kişisel verilerimin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, işbu verilerin işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerimin silinmesini veya yok edilmesini isteme, bu düzeltme ve silinme taleplerinin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendi aleyhime bir sonucun ortaya çıkmasına itiraz etme, kişisel verilerimin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarımın olduğunu ve bu hakları kullanmak için kimliğimi tespit edici gerekli bilgiler ile kullanmayı talep ettiğim hakkıma yönelik açıklamaları da içeren talebimi şirkete iletme hakkına sahip olduğumu kabul ediyorum.</div>
                <div className='mt-2'>KVK Kanunu’nda tanımlanan özel nitelikli kişisel verilerim de dahil olmak üzere ilgili kişisel verilerimin işlenmesine, ilgili süreç kapsamında işlenme amacı ile sınırlı olmak üzere kullanılmasına ve paylaşılmasına, gereken süre zarfında saklanmasına açık rızam olduğunu ve bu hususta tarafıma gerekli aydınlatmanın yapıldığını; işbu metni ve Aydınlatma Metnini okuduğumu, siteye girişim ile bu bildirimi onaylamış olduğumu, gerekli KVKK bilgilendirme-işleme ve aydınlatma yükümlülüklerini açıkça kabul etmiş olduğumu. Sitede kaldığım sürece ve verdiğim bilgiler uyarınca gerekli bilgilendirme ve izinlerin yapılmış sayılacağını kabul ettiğimi kabul beyan ve taahhüt ederim. Aksi beyanda siteyi kullanmamam gerektiğinden dolayı doğacak zarar taleplerinden feragat etmiş ve şikayet haklarımın sona ermiş olacağını kabul etmekteyim.</div>

            </div>
        </Modal>
        <Modal isOpen={modal3Open} onRequestClose={closeModal3} style={customStyles} >
            <div className='flex justify-end'><button onClick={closeModal2}><TbX strokeWidth={2.7} size={22} /></button></div>
            <div className='text-2xl font-medium'>Kişisel Verilerin Korunması Kanunu (KVKK) Bildirimi</div>
            <div className='mt-2'>
                <div className='mt-2'>Sayın Ziyaretçi,</div>
                <div className='mt-2'>Otesinegec.com olarak, kişisel verilerinizin gizliliğini önemsiyor ve korunmasına büyük önem veriyoruz. Bu nedenle, Kişisel Verilerin Korunması Kanunu (KVKK) çerçevesinde size gerekli bilgilendirmeyi yapmak istiyoruz:</div>
                <div className='mt-2'>1. Veri Sorumlusu: Otesinegec.com, kişisel verilerinizin işlenmesinden sorumlu veri sorumlusudur.</div>
                <div className='mt-2'>2. Kişisel Verilerin İşlenme Amaçları: Sizinle olan etkileşimimizde, fotoğraflarınız ve videolarınız gibi kişisel verileriniz, Level UP etkinliği kapsamında AVM’lerde gerçekleşen saha etkinlikleri için kullanılabilir. Bu verilerin işlenme amacı, etkinlik katılımcılarının deneyimlerini paylaşmak, etkinlik hakkında bilgi vermek ve etkinliği tanıtmak amacıyla kullanılmaktadır.</div>
                <div className='mt-2'>3. Toplanan Kişisel Veriler: Otesinegec.com üzerinde, adınız, fotoğrafınız, videolarınız gibi kişisel verileriniz etkinlikle ilgili paylaşımlar için toplanabilir.</div>
                <div className='mt-2'>4. Kişisel Verilerin Saklanma Süresi: Kişisel verileriniz, işlenme amacının gerektirdiği süre boyunca saklanır ve ardından güvenli bir şekilde silinir veya anonim hale getirilir.</div>
                <div className='mt-2'>5. Kişisel Verilere Erişim ve Güncelleme: KVKK kapsamında size tanınan haklar doğrultusunda, kişisel verilerinizle ilgili taleplerinizi bize iletebilirsiniz. Bu haklarınız arasında, kişisel verilerinize erişme, düzeltilme, silinme veya işleme itiraz etme hakları bulunmaktadır.</div>
                <div className='mt-2'>6. Kişisel verilerin aktarılacağı alıcı grupları: Kişisel verileriniz; KVKK’nın kişisel verilerin aktarılmasına ilişkin hükümleri kapsamında ve talep halinde yurt içindeki; resmi kurum ve kuruluşlara ve iş ortaklarımıza aktarılabilmektedir.</div>
                <div className='mt-2'>7. Açık rıza beyanı: Devam eden pencereye onay vererek ilgili pencerede yer alan hususlarda açık rızanız bulunduğunu kabul etmiş olacaksınız.</div>
                <div className='mt-2'>8. Hukuki Sebep: Firmamızla hukuki ilişkinizin kurulması esnasında ve söz konusu ilişkinin devamı süresince sizlerden, üçüncü kişilerden ve yasal mercilerden olmak kaydıyla internet sitesi, muhtelif sözleşmeler, elektronik posta, başvuru formları gibi araçlar üzerinden, Kurumumuz ile yapılan yazılı veya sözlü iletişim kanalları aracılığıyla sözlü, yazılı veya elektronik ortamda toplanmaktadır. Bu doğrultuda toplanan kişisel verileriniz KVKK’nın 5. ve 8. maddelerinde belirtilen hukuka uygunluk sebeplerine dayanılarak işlenmektedir.</div>
                <div className='mt-2'>KVKK kapsamında kişisel verilerinizin işlenmesi ile ilgili herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.</div>
                <div className='mt-2'>İletişim yetkili mail: damla.lebut@ngevent.com</div>
                <div className='mt-2'>KVKK Sorumlusu: Damla Lebut</div>
                <div className='mt-2'>Kişisel veri işleme faaliyetinin açık rıza şartına dayalı olarak gerçekleştirilmesi halinde, aydınlatma yükümlülüğü ve açık rızanın alınması işlemlerinin ayrı ayrı yerine getirilmesi gerekmektedir.</div>
                <div className='mt-2'>Kanunun 10 uncu maddesinin birinci fıkrasının (ç) bendinde yer alan “hukuki sebep” ten kasıt, aydınlatma yükümlülüğü kapsamında kişisel verilerin Kanunun 5 ve 6 ncı maddelerinde belirtilen işleme şartlarından hangisine dayanılarak işlendiğidir. Aydınlatma yükümlülüğünün yerine getirilmesi esnasında hukuki sebebin açıkça belirtilmesi gerekmektedir.</div>
                <div className='mt-2'>Aydınlatma yükümlülüğü kapsamında, kişisel verilerin aktarılma amacı ve aktarılacak alıcı grupları belirtilmelidir.</div>
                <div className='mt-2'>Aydınlatma yükümlülüğünün yerine getirilmesi, ilgili kişinin talebine bağlı değildir.</div>
                <div className='mt-2'>Aydınlatma yükümlülüğünün yerine getirildiğinin ispatı veri sorumlusuna aittir.</div>
                <div className='mt-2'>Sicile kayıt yükümlülüğünün bulunması durumunda, aydınlatma yükümlülüğü çerçevesinde ilgili kişiye verilecek bilgiler, Sicile açıklanan bilgilerle uyumlu olmalıdır.</div>
                <div className='mt-2'>AYDINLATMA YÜKÜMLÜLÜĞÜNÜN YERİNE GETİRİLMESİNDE UYULACAK USUL VE ESASLAR HAKKINDA TEBLİĞ</div>

            </div>
        </Modal>
        </>
    );
}

export default Footer;