
import {  useState , useEffect} from 'react'
import axios from 'axios'

import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {showError, showSuccess, date_tr} from '../components/commonFuncs'
import { useNavigate,} from 'react-router-dom';

import {PlusIcon, TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline'



function AdminTeams() {
  const navigate = useNavigate();
  
  const [etaps, setEtaps] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    checkAdmin();

    getTeams();

  },[]);

  const checkAdmin  = () => {

    let data = {
      token : localStorage.getItem('otesi_admin_token')
    };

    axios.post(process.env.REACT_APP_API_URL+'admin_check',JSON.stringify(data)).then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
      }else{
        localStorage.removeItem('otesi_admin_token')
        navigate('/')
      }
    });
  }
  const getTeams  = () => {
    axios.get(process.env.REACT_APP_API_URL+'admin_teams_all').then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
        setTeams(response.data.teams)
      }
    });
  }



  const deleteIt = (e) => {

    let data = {
      token : localStorage.getItem('otesi_admin_token'),
      id : e.id
    };
    
    axios.post(process.env.REACT_APP_API_URL+'admin_delete_from_team',JSON.stringify(data) ).then(function (response) {
        if(response.data.result === "Success"){
          showSuccess("Oyuncu başarıyla silindi!");
          getTeams();
        }
    });
    
    
  }

  const goToStageAdd = () => {
    navigate('/admin_otesi/add_stage')
  }



    return (
      <>
      <div>
        <AdminLeft page={"teams"} />

        <div className="lg:pl-72">

          <AdminHeader  />

          <main className="p-8 ">
            <div className="flex">
              <div className='font-medium text-3xl flex-1'>Takımlar</div>
              
            </div>

            {teams.map((val,index) => (
              <div key={index}>

                <div className="text-xl mt-4 text-gray-700 font-semibold">{val.name}</div>
                
            <table className="min-w-full divide-y mt-8 divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" width={50} className="p-2 text-left font-semibold text-gray-900 ">#</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Oyuncu Adı</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Email</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900">Telefon</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {val.players.map((q,index) => (
                  <tr key={index}>

                    <td className="p-2 text-left  text-gray-900">{ + index+1}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.name}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.email}</td>
                    <td className="p-2 text-left  text-gray-900">{q.phone}</td>

                    <td  className="p-2 flex justify-end">

                      <a href="#" onClick={() => { if (window.confirm('Silmek istediğinize emin misiniz?')) deleteIt(q)   } } className="text-red-600 ">
                        <TrashIcon className='w-6' />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

              </div>
            ))}


          </main>
        </div>
      </div>


      </>
    );
}

  export default AdminTeams;