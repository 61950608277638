import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import axios from 'axios'
import {  useState , useEffect} from 'react'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

function AdminDashboard() {
  const navigate = useNavigate();



  useEffect(() => {

    checkAdmin();

  },[]);

  
  
  const checkAdmin  = () => {

    let data = {
      token : localStorage.getItem('otesi_admin_token')
    };

    axios.post(process.env.REACT_APP_API_URL+'admin_check',JSON.stringify(data)).then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
      }else{
        localStorage.removeItem('otesi_admin_token')
        navigate('/')
      }
    });
  }


  

    return (
      <>
      <div>
        <AdminLeft page="dashboard" />

        <div className="lg:pl-72">

          <AdminHeader />

          <main className="p-8 ">
            <div className="">
              Ötesine Geç kontrol paneline hoşgeldiniz...
            </div>
          </main>
        </div>
      </div>


      </>
    );
}

  export default AdminDashboard;