import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {  useState , useEffect} from 'react'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';


function AdminLogout() {
  const navigate = useNavigate();

  useEffect(() => {
    cikis()
  }, [])
  

  const cikis = () => {
    localStorage.removeItem('otesi_admin_token')
    navigate('/')
  }

    return (
      <>
      <div>
        <AdminLeft page="dashboard" />

        <div className="lg:pl-72">

          <AdminHeader />

          <main className="p-8 ">
            <div className="">
              Çıkış Yapıyorsunuz, lütfen bekleyin...
            </div>
          </main>
        </div>
      </div>


      </>
    );
}

  export default AdminLogout;