
import {  useState , useEffect, useRef} from 'react'
import axios from 'axios'
import Modal from 'react-modal';

import {showError, showSuccess,date_tr} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import levelup_logo_main from '../assets/images/levelup_logo_main.png'
import sponsorlar from '../assets/images/sponsorlar.png'
import game_fortnite from '../assets/images/game_fortnite.jpg'
import game_f1 from '../assets/images/game_f1.jpg'
import icon_1 from '../assets/images/icon_1.png'
import icon_2 from '../assets/images/icon_2.png'
import icon_3 from '../assets/images/icon_3.png'
import odul_1 from '../assets/images/odul_1.png'
import odul_2 from '../assets/images/odul_2.png'
import odul_6 from '../assets/images/odul_6.png'
import odul_31 from '../assets/images/odul_31.png'
import odul_41 from '../assets/images/odul_41.png'
import odul_5 from '../assets/images/odul_5.png'

import {   scroller} from 'react-scroll';
import { TbMenuDeep,TbX } from "react-icons/tb";
import Footer from '../components/Footer'
import { MapPinIcon, ClockIcon} from '@heroicons/react/24/outline'


const customStyles = {
  content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#151132',
      color: '#FFF',
      borderColor: '#00D2FF',
      borderWidth : 2

      
  },
};

function Home() {
  const navigate = useNavigate();
  const [stages, setstages] = useState([]);

  


  useEffect(() => {
    getStages()

  },[]);

  const getStages =() => {
    axios.get(process.env.REACT_APP_API_URL+'web_stages').then(function (response) {
      if(response.data.result === "Success"){
        setstages(response.data.stages)
        //console.log(response.data.stages);
      }
    });
  }

  const scrollToOduller = (nere) => {
    setleft_menu(false)
    scroller.scrollTo(nere, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: 50, 
    });

  }


  
  const goToregister = () => {
    navigate('/register')
  }
  
  const goTologin = () => {
    navigate('/login')
  }

  const [left_menu, setleft_menu] = useState(false);
  const handle_left_menu = () => {
    setleft_menu(true)
  }
  const close_left_menu = () => {
    setleft_menu(false)
  }

  useEffect(() => {
    if(left_menu) {

      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'unset' ;

    }
  }, [left_menu])


  const [selected_state, setselected_state] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  function openModal(id) { setselected_state(id); setModalOpen(true); }
  function closeModal() { setModalOpen(false); }
  const [selected_state_details, setselected_state_details] = useState({});
  const [winners, setwinners] = useState([]);

  
  useEffect(() => {
    if(selected_state !== 0){
      axios.get(process.env.REACT_APP_API_URL+'web_stage_winners?stage='+selected_state).then(function (response) {
        if(response.data.result === "Success"){
          setselected_state_details(response.data.stage)
          setwinners(response.data.winners)
        }
      });
    }
  }, [selected_state])

  const sifrele = (metin) => {

    const myArray = metin.split(" ");
    var yenimetin = ""
    myArray.forEach(e => {
      yenimetin = yenimetin + e[0]+"** "
    });

    return yenimetin;
  }

  const ceversn = (gelen) => {

    var decimal = gelen - Math.floor(gelen)
    var basi = Math.floor(gelen - decimal)


    let dk = Math.floor(basi / 60)
    let sn = basi - (dk * 60)
    return String(dk).padStart(2, '0') + " : " + String(sn).padStart(2, '0') + " : " + String(Math.round(decimal * 1000)).padStart(3, '0') 

  }



  

    return (
      <div className='web_bg min-h-screen'>

        <div className='web_header_bg relative'>

          <div className='mx-auto max-w-7xl px-6 lg:px-8 '>  
            
            <div className='pt-4 flex items-center'>

              <div className='flex-1'><img  src={levelup_logo_main} className='w-40 cursor-pointer' alt='' /></div>

              <div className=' items-center space-x-6 hidden md:flex' >
                <div><button onClick={() => scrollToOduller('nasil_oynanir')} className='text-white' >Nasıl Oynanır?</button></div>
                <div><button onClick={() => scrollToOduller('takvim')} className='text-white' >Takvim</button></div>
                <div><button onClick={() => scrollToOduller('oyunlar')} className='text-white' >Oyunlar</button></div>
                <div><button onClick={() => scrollToOduller('oduller')} className='text-white' >Ödüller</button></div>
                <div><button onClick={goTologin} className='text-white ' >Giriş</button></div>
                <div><button onClick={goToregister} className='text-white pink_button px-4 py-3 font-semibold rounded-lg' >Kayıt Ol</button></div>
              </div>

              <TbMenuDeep onClick={handle_left_menu} size={36} strokeWidth={1.2} className='block md:hidden' />

            </div>

          </div>

          <div className='absolute left-0 right-0 bottom-5 md:bottom-20'>
            <div className='sponsor_image mx-auto'>
              <img src={sponsorlar} className='w-full' alt='' />
            </div>
          </div>  

          {left_menu &&
          <>
            <div onClick={close_left_menu} className='fixed bg-opacity-50 bg-black left-0 right-0 bottom-0 top-0 z-10 backdrop-blur-sm'></div>
            <div className='fixed top-0 bottom-0 web_bg z-20 menuss p-6 '>

                <div className='flex '>
                  <div className='flex-1'><img  src={levelup_logo_main} className='w-32 mb-10' alt='' /></div>
                  <TbX onClick={close_left_menu} className='' size={30} />
                </div>


                <div className='mb-5'><button onClick={() => scrollToOduller('nasil_oynanir')} className='text-white' >Nasıl Oynanır?</button></div>
                <div className='mb-5'><button onClick={() => scrollToOduller('takvim')} className='text-white' >Takvim</button></div>
                <div className='mb-5'><button onClick={() => scrollToOduller('oyunlar')} className='text-white' >Oyunlar</button></div>
                <div className='mb-5'><button onClick={() => scrollToOduller('oduller')} className='text-white' >Ödüller</button></div>

                <div className=' left-0 right-0 absolute bottom-10'>
                  <div className='justify-center flex items-center space-x-6'>
                    <div><button onClick={goTologin} className='text-white ' >Giriş</button></div>
                    <div><button onClick={goToregister} className='text-white pink_button px-3 py-2 font-semibold rounded-lg' >Kayıt Ol</button></div>
                  </div>
                </div>
            </div>
          </>
          }


        </div>

        <div className='text-center pt-20 py-10' ><button onClick={goToregister} className='text-white pink_button px-8 py-5 text-lg font-semibold rounded-lg' >HEMEN KAYIT OL</button></div>


        <div id="nasil_oynanir" className='mx-auto  max-w-7xl px-6 lg:px-8 py-20 '>
          <div className='lined_title text-center md:text-4xl text-3xl  tracking-widest drop-shadow'>NASIL OYNANIR?</div>

          <div className='flex flex-col md:flex-row space-x-0 md:space-x-20 md:space-y-0 space-y-8 justify-center mt-10 place-items-center' >

            <div className='text-center w-60 '>
              <img src={icon_1} alt='' className='w-28 mx-auto' />
              <div className='font-semibold text-xl mb-1'>Kayıt Ol</div>
              <div>Katılmak istediğin günü seç ve yerini rezerve et</div>
            </div>
            <div className='text-center w-60 mx-auto'>
              <img src={icon_2} alt='' className='w-28 mx-auto' />
              <div className='font-semibold text-xl mb-1'>Etkinlik Alanına Gel</div>
              <div>Seçtiğin gün ve saatte etkinlik alanına gel</div>
            </div>
            <div className='text-center w-60 mx-auto'>
              <img src={icon_3} alt='' className='w-28 mx-auto' />
              <div className='font-semibold text-xl mb-1'>Oyna, Puanları Topla</div>
              <div>F1 ve Fortnite skorlarınla günün kazananı ol</div>
            </div>
          </div>

          <div className='font-semibold mt-20 mb-2'>KURALLAR</div>
          <div>- Etkinliğimizde yer alabilmek için 14 yaşını doldurmuş olmalısın.</div>
          <div>- Fortnite ve Formula 1’de hız ve hedef odaklı yarış, zamanın ötesine geç!</div>
          <div>- Skorlarını sürekli arttırarak en iyi performansı sergile. Unutma, Level Up'tasın!</div>
          <div>- Performansın ve eğlencenin ötesine kim geçerse, zafer onun olacak.</div>
          <div className='mt-2'><a href='https://docs.google.com/document/d/1s-lx22QIwN_PlLmPY5ZrCfiQT-W8qG20CuBB0ZWqXjg/' target='_blank' className='text_pink'>Tüm Kurallar</a></div>

        </div>

        <div id="takvim" className='mx-auto  max-w-7xl px-6 lg:px-8 py-20 '>
          <div className='lined_title text-center text-4xl  tracking-widest drop-shadow'>TAKVİM</div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mt-10'>
            {stages.map((val,index) => (
              <div key={index} className={ ' rounded-xl overflow-hidden'} >

                <div className='bg-white p-3 font-semibold flex'>

                  <div className='text_dark flex-1'>{date_tr(val.dates)}</div>
                  
                </div>

                <div className='h-1 bg_gra'></div>
                <div className='bg_stage_alt px-4 py-6'>
                  <div className='font-semibold text-lg mb-5'>{val.name}</div>
                  <div className='flex space-x-1'>
                    <MapPinIcon className='w-5' />
                    <div>{val.address}</div>
                  </div>
                  <div className='flex space-x-1 mt-2'>
                    <ClockIcon className='w-5' />
                    <div>{val.hours}</div>
                  </div>


                  {val.influencer !== "" && 
                  <div className='flex space-x-1 mt-2'>
                    <div>Influencer : {val?.influencer}</div>
                  </div>
                  }

                  {val.cosplayer !== "" && 
                  <div className='flex space-x-1 mt-2'>
                    <div>Cosplayerlar : {val.cosplayer}</div>
                  </div>
                  }
                  
                  <div className='mt-3 flex justify-end'>
                    <button onClick={() => openModal(val.id)} className={(val.winners === 0 ? 'opacity-40 text-purple-300 ' : 'te_m ') + ' text-sm font-semibold '}>SONUÇLAR</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div id="oyunlar" className='mx-auto  max-w-7xl px-6 lg:px-8 py-20 '>
          <div className='lined_title text-center text-4xl  tracking-widest drop-shadow'>OYUNLAR</div>

          <div className='flex flex-col md:flex-row md:space-x-10 md:space-y-0 space-y-10 mt-8'>
            <div><img src={game_f1} className='w-full rounded-lg' alt='' /></div>
            <div><img src={game_fortnite} className='w-full rounded-lg' alt='' /></div>
          </div>

        </div>

        <div id="oduller" className='mx-auto  max-w-7xl px-6 lg:px-8 py-20 '>
          <div className='lined_title text-center text-4xl  tracking-widest drop-shadow'>ÖDÜLLER</div>

          <div className='flex  flex-col md:flex-row  md:space-x-10 md:space-y-0 space-y-4 mt-20'>
            <div className='flex-1'>
              <div className='text-3xl mb-2'>BÜYÜK FİNAL BİRİNCİSİ</div>
              <div>8 Aralık 2024’de düzenlenecek Büyük Final etkinliğinde en yüksek skora ulaşıp birinci olan katılımcımız Vatan Bilgisayar mağazalarında geçerli 300.000 TL’lik hediye çeki kazanacak.</div>
            </div>
            <div className='flex-1'><img src={odul_1} className='w-full rounded-lg' alt='' /></div>
          </div>

          <div className='border-t border-gray-200 mt-20 w-96 mx-auto'></div>

          <div className='flex  flex-col md:flex-row  md:space-x-10 md:space-y-0 space-y-4  mt-20'>
            <div className='flex-1'>
              <div className='text-3xl mb-2'>TÜM ETKİNLİK BİRİNCİLERİ</div>
              <div>Level UP etkinlikleri dahilinde düzenlenecek saha etkinliklerine katılıp günün birincisi olan katılımcılarımız Vatan Bilgisayar mağazalarında geçerli 10.000 TL’lik hediye çekinin yanı sıra ROG Harpe Ace Aim Lab X Gaming Mouse ve ROG Delta S Core Gaming Kulaklık kazanacak.</div>
            </div>
            <div className='flex-1'>
              <img src={odul_2} className='w-full rounded-lg' alt='' />
              <img src={odul_31} className='w-full rounded-lg mt-8' alt='' />
            </div>
          </div>

          <div className='border-t border-gray-200 mt-20 w-96 mx-auto'></div>




          <div className='flex  flex-col md:flex-row  md:space-x-10 md:space-y-0 space-y-4  mt-20'>
            <div className='flex-1'>
              <div className='text-3xl mb-2'>TÜM ETKİNLİK İKİNCİLERİ</div>
              <div>Level UP etkinlikleri dahilinde düzenlenecek saha etkinliklerine katılıp günün ikincisi olan katılımcılarımız ROG Delta S Core Gaming Kulaklık kazanacak.</div>
            </div>
            <div className='w-full md:w-128'><img src={odul_41} className='w-full rounded-lg' alt='' /></div>
          </div>

          <div className='flex  flex-col md:flex-row  md:space-x-10 md:space-y-0 space-y-4  mt-20'>
            <div className='flex-1'>
              <div className='text-3xl mb-2'>TÜM ETKİNLİK ÜÇÜNCÜLERİ</div>
              <div>Level UP etkinlikleri dahilinde düzenlenecek saha etkinliklerine katılıp günün üçüncüsü olan katılımcılarımız ROG Harpe Ace Aim Lab X Gaming Mouse kazanacak.</div>
            </div>
            <div className='w-full md:w-128'><img src={odul_5} className='w-full rounded-lg' alt='' /></div>
          </div>


          <div className='border-t border-gray-200 mt-20 w-96 mx-auto'></div>


          
          <div className='flex  flex-col md:flex-row  md:space-x-10 md:space-y-0 space-y-4  mt-20'>
            <div className='flex-1'>
              <div className='text-3xl mb-2'>TÜM KATILIMCILARA</div>
              <div>Level UP etkinlikleri dahilinde düzenlenecek saha etkinliklerine katılıp tamamlayan tüm katılımcılar Star Wars Outlaws erişim kodu kazanacak.</div>
            </div>
            <div className='flex-1'>
              <a href={'https://softwareoffer.intel.com/Campaign/LearnMore/14c84f0d-1ada-4ff3-bc55-ebf492de5958'} target='_blank'>
              <img src={odul_6} className='w-full rounded-lg' alt='' />
              </a>
            </div>
          </div>

          <div className='mt-20'>
            <div className='text-2xl mb-4'>Ana Anahtar Kodu Nasıl Kullanılır?</div>
            <div className='mb-1'>1. <a href='https://softwareoffer.intel.com' target='_blank' className='text_pink'>softwareoffer.intel.com</a> adresine gidin.</div>
            <div className='mb-1'>2. Hesabınıza giriş yapın veya heni bir hesap oluşturun. Lütfen unutmayın: Hesap doğrulaması ve erişim için telefon numaranız gereklidir.</div>
            <div className='mb-1'>3. Hesabınız onaylandıktan sonra giriş yapın, Ana Anahtarınızı girin ve ekrandaki talimatları takip ederek 15 Eylül 2024 tarihine kadar yazılımınızı alın.</div>
            <div className='mb-1'>4. Fırsatla ilgili Şartlar ve Koşulları kabul edin ve kısa bir anket tamamlayın.</div>
            <div className='mb-1'>5. Mevcut yazılım ürünlerini seçin ve indirin.</div>
            <div className='font-semibold mb-1'>Ana Anahtar: XXXXXXXXXXXX</div>
            <div className='text-sm mb-1'>Not: Ana Anahtar, tam olarak gösterilen şekilde girilmelidir. Silinirse veya kaybedilirse değiştirilemez.</div>
          </div>

        </div>




        <div  className='mx-auto  max-w-7xl px-6 lg:px-8  '>
          <Footer />
        </div>

        <Modal isOpen={modalOpen} onRequestClose={closeModal} style={customStyles} >
            <div className='flex justify-end'><button onClick={closeModal}><TbX strokeWidth={2.7} size={22} /></button></div>
            <div className='text-2xl font-medium mb-1'> En İyi 10 Tablosu</div>
            <div className='text-lg font-medium'> {selected_state_details.name} - <span className='text-base font-normal'>{date_tr(selected_state_details.dates)}</span></div>
            
            <table className="min-w-full divide-y mt-8 ">
              
              <tbody className="divide-y divide-slate-700">
                {winners.map((q,index) => (
                  <tr key={index}>

                    <td className="py-3 text-left">{index+1}</td>
                    <td className="py-3 text-left">{sifrele(q?.player?.name)}</td>
                    <td className="py-3 text-left">{q?.player?.nickname}</td>
                    <td className="py-3 text-end">{ceversn(q.toplam)}</td>


                  </tr>
                ))}
              </tbody>
            </table>


            {winners.length === 0 &&
            <div className='my-20 text-center text-yellow-100'>Etap sonucu henüz açıklanmamıştır!
            </div>
            }



        </Modal>

      </div>
    );
}

  export default Home;