
import {  useState , useEffect, Fragment} from 'react'
import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react'

import {
    Bars3Icon,
    ArrowLeftStartOnRectangleIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

function AdminLeft() {
    const navigate = useNavigate();

    const userNavigation = [
        { name: 'Your profile', href: '#' },
        { name: 'Sign out', href: '#' },
      ]

    function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
    }
    const [sidebarOpen, setSidebarOpen] = useState(false)
    
    
return (
    <>

        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4  sm:gap-x-6 sm:px-6 lg:px-8"> 
            
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="flex-1 flex justify-end">

                    <a href={"/admin_otesi/logout"}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2  font-semibold leading-6 text-slate-800 hover:bg-slate-800 hover:text-white">
                    <ArrowLeftStartOnRectangleIcon
                        className="h-6 w-6 shrink-0 text-slate-800 group-hover:text-white"
                        aria-hidden="true"
                    />
                    Çıkış
                    </a>
            </div>
        </div>


        
    </>
    );
}

export default AdminLeft;