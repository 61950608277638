
import {  useState , useEffect, useRef} from 'react'
import axios from 'axios'
import { useMask } from '@react-input/mask';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import Modal from 'react-modal';
import { TbSquare,TbSquareCheck, TbX } from "react-icons/tb";
import Footer from '../components/Footer'


import levelup_logo_main from '../assets/images/levelup_logo_main.png'


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    
  },
};

function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  
  const handleEmail = (e) => { setEmail(e.target.value) }

  const goToHome = () => {
    navigate('/')
  }


  
  const validateEmail = (em) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(em);
  }


  const save = () => {
    let _ready = true

    if(!validateEmail(email)){_ready = false; showError("Lütfen email adresinizi giriniz");}

    if(_ready){
      let data = {
        email : email,
      };

      axios.post(process.env.REACT_APP_API_URL+'web_forgot_password',JSON.stringify(data))
        .then(function (response) {
              console.log(response.data);
              if(response.data.result === 'Success'){
                  showSuccess("Şifre yenileme linki gönderildi.")
                  //localStorage.setItem('otesi_token', response.data.token);
                  navigate("/forgotpassword_sent")
    
              }else{
                showError("Üye bulunamadı. Lütfen email adresinizi kontrol ediniz!");
              }

        })

    }

  }


    return (
      <>
      <div className='web_bg min-h-screen'>
          
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>

            <div className='pt-4'><img onClick={goToHome} src={levelup_logo_main} className='w-40 cursor-pointer' alt='' /></div>

            <div className='mt-10'>
              <div className='text-center text-4xl tracking-widest	'>ŞİFREMİ UNUTTUM</div>
              <div className='text-center  mt-2'>Kayıt olduğun email adresini girerek şifreni sıfırlayabilirsin.</div>
            </div>



            <div className="mt-10 lg:w-[36rem] w-full mx-auto" >
              <label  className="block font-medium ">Email Adresi</label>
              <div className="mt-2">
                <input type="text" value={email} onChange={handleEmail} placeholder='Email Adresi'
                  className="block w-full bg-transparent rounded-xl border border-white py-3 px-4 text-white placeholder-slate-500"/>
              </div>
            </div>


            <div className="mt-8 lg:w-[36rem] w-full mx-auto" >
              <button onClick={save}
              className="flex w-full justify-center rounded-xl pink_button px-3 py-4  font-semibold leading-6 text-white shadow-sm " >
                ŞİFREMİ SIFIRLA
              </button>
            </div>

            

          </div>



      </div>
      
      </>
    );
}

  export default ForgotPassword;