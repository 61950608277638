
import {  useState , useEffect, Fragment} from 'react'
import axios from 'axios'

import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline'
import { TbCircle,TbCircleCheck } from "react-icons/tb";




function AdminPlayers() {
  const navigate = useNavigate();
  let { id } = useParams();

  
  const [players, setPlayers] = useState([]);
  const [stage, setStage] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(20);
  const [toplam, setToplam] = useState(0);
  const [score_checked, setscore_checked] = useState(false);

  const [keyword, setkeyword] = useState("");
  const handleKeyword = (e) => {
    setPage(0)
    setkeyword(e.target.value)
}



  useEffect(() => {

    getStageDetails();
    

  },[]);

  useEffect(() => {

    getPlayers();

  },[page,keyword,score_checked]);

  const getStageDetails = () => {
    axios.get(process.env.REACT_APP_API_URL+'admin_stage/'+id).then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
        setStage(response.data.stage)
      }
    });
  }

  
  const getPlayers = () => {
    var sc = 0;
    if(score_checked){sc = 1}



    axios.get(process.env.REACT_APP_API_URL+'admin_players/'+id+'?limit='+limit+'&page='+page+'&keyword='+keyword+'&sc='+sc).then(function (response) {
      if(response.data.result === "Success"){

        var gg = [];
        response.data.players.forEach(element => {
          if(element.player_details != null){
            gg.push(element)
          }
        });
        setPlayers(gg)


        setToplam(response.data.total)
      }
    });
  }

  const deleteIt = (e) => {

    let data = {
      token : localStorage.getItem('otesi_admin_token'),
      id : e.id
    };

    axios.post(process.env.REACT_APP_API_URL+'admin_delete_player',JSON.stringify(data) ).then(function (response) {
        if(response.data.result === "Success"){
          showSuccess("Oyuncu başarıyla silindi!");
          getPlayers();
        }
    });
    
  }


  const goToDashboard = () => {
    navigate('/hosgeldiniz')
  }


  const checkskor = () => {
    setscore_checked(!score_checked);
  }


    return (
      <>
      <div>
        <AdminLeft page={"players"} />

        <div className="lg:pl-72">

          <AdminHeader page="players" />

          <main className="p-8">

            <div className='flex items-center space-x-10'>
              <div className='flex-1'>
                <div className='font-medium text-3xl '>{stage?.name}</div>
                <div className='font-medium text-xl'>Etap Oyuncuları</div>
              </div>  

              <div onClick={checkskor} className='flex items-center space-x-1 cursor-pointer'>
                {score_checked ?
                
                <TbCircleCheck size={20} />
                :
                <TbCircle size={20} />
              }
                <div>Skor Girilenler</div>
              </div>

              <input type="email" placeholder='Ara' value={keyword} onChange={handleKeyword}
                    className="block  rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  "  />
            </div>
            

            <table className="min-w-full divide-y mt-8 divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" width={50} className="p-2 text-left font-semibold text-gray-900 ">#</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Ad Yaş, Nickname</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900 ">Email Adresi</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900">Telefon</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900">Takım</th>
                  <th scope="col" className="p-2 text-left font-semibold text-gray-900">Toplam Süre</th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {players.map((q,index) => (
                  <tr key={index}>

                    <td className="p-2 text-left  text-gray-900">{(page*20) + index+1}</td>
                    <td className=" p-2 text-left  text-gray-900">
                      <div className='font-semibold'>{q?.player_details?.name} - {q?.player_details?.age}</div>
                      <div className='text-slate-500'>{q?.player_details?.nickname}</div>
                    </td>
                    <td className=" p-2 text-left  text-gray-900">{q?.player_details?.email}</td>
                    <td className=" p-2 text-left  text-gray-900">{q?.player_details?.phone}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.team}</td>
                    <td className=" p-2 text-left  text-gray-900">{q.toplam}</td>

                    <td  className="p-2 flex  justify-end">
                    <a href={"/admin_otesi/player_puan_edit/" + q.id + "/" + id}  className="bg-emerald-700 px-2 py-1 rounded-md text-white me-5">
                        Süreler
                      </a>
                      <a href={"/admin_otesi/player_edit/" + q.player + "/" + id }  className="text-indigo-600 me-5">
                        <PencilSquareIcon  className='w-6' />
                      </a>
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {keyword === "" &&
            <div className='mt-10 border-t p-2 flex'>
              <div className='flex-1'>{toplam} oyuncu</div>
              <div className='flex space-x-2'>
              {[...Array(Math.ceil(toplam/limit)).keys()].map((i,index) => (
                    <button key={i} onClick={()=>setPage(i)}  className={(page === i ? 'bg-slate-700' : 'bg-slate-400') + ' text-white  px-2 py-1 rounded-lg '}>{i+1}</button>
                  ))}
              </div>
            </div>
            }



            
          </main>
        </div>
      </div>


      </>
    );
}

  export default AdminPlayers;