
import {  useState , useEffect, useRef} from 'react'
import axios from 'axios'

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { TbMailFast } from "react-icons/tb";
import Footer from '../components/Footer'


import levelup_logo_main from '../assets/images/levelup_logo_main.png'



function ResetPassword() {
  const navigate = useNavigate();
  let { token } = useParams();


  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");

  
  const handlePass = (e) => { setPass(e.target.value) }
  const handlePass2 = (e) => { setPass2(e.target.value) }

  const goToHome = () => {
    navigate('/')
  }

  const save = () => {
    let _ready = true
    if(pass.length <= 5){_ready = false; showError("Lütfen 6 karakterli yeni şifreni belirle");}
    if(pass !== pass2){_ready = false; showError("Şifre ve tekrarı aynı olmalıdır");}
    
    if(_ready){
      let data = {
        pass : pass,
        pass2 : pass2,
        token : token
      };
      console.log(data);

      axios.post(process.env.REACT_APP_API_URL+'web_reset_password',JSON.stringify(data))
        .then(function (response) {
              console.log(response.data);
              if(response.data.result === 'Success'){
                  showSuccess("Yeni şifreniz başarıyla kaydedildi, lütfen giriş yapın");
                  navigate("/login")
    
              }else{
                showError("Bir hata oluştu tekrar deneyin!");
              }

        })

    }
  }
  



    return (
      <>
      <div className='web_bg min-h-screen'>
          
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>

            <div className='pt-4'><img onClick={goToHome} src={levelup_logo_main} className='w-40 cursor-pointer' alt='' /></div>

            <div className='mt-10 place-items-center flex flex-col'>
              <div className='text-center text-4xl tracking-widest	'>ŞİFRENİ SIFIRLA</div>
              <div className='text-center  mt-2'>Lütfen yeni şifreni belirle.</div>
            </div>

            <div className="mt-6 lg:w-[36rem] w-full mx-auto" >
              <label  className="block font-medium ">Yeni Şifre</label>
              <div className="mt-2">
                <input type="password" value={pass} onChange={handlePass} placeholder='*******'
                  className="block w-full bg-transparent rounded-xl border border-white py-3 px-4 text-white placeholder-slate-500"/>
              </div>
            </div>

            <div className="mt-6 lg:w-[36rem] w-full mx-auto" >
              <label  className="block font-medium ">Yeni Şifre Tekrar</label>
              <div className="mt-2">
                <input type="password" value={pass2} onChange={handlePass2} placeholder='*******'
                  className="block w-full bg-transparent rounded-xl border border-white py-3 px-4 text-white placeholder-slate-500"/>
              </div>
            </div>


            <div className="mt-8 lg:w-[36rem] w-full mx-auto" >
              <button onClick={save}
              className="flex w-full justify-center rounded-xl pink_button px-3 py-4  font-semibold leading-6 text-white shadow-sm " >
                KAYDET
              </button>
            </div>

            

          </div>



      </div>
      
      </>
    );
}

  export default ResetPassword;