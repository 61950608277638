
import {  useState , useEffect, } from 'react'
import axios from 'axios'
import {Routes, Route, useNavigate, useParams } from 'react-router-dom';

import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {showError, showSuccess} from '../components/commonFuncs'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




function AdminPlayerEdit() {
  const navigate = useNavigate();
  let { playerId } = useParams();
  let { stageId } = useParams();


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nickname, setNickname] = useState("");
  const [instagram, setInstagram] = useState("");
  const [gun, setGun] = useState(0);
  const [ay, setAy] = useState(0);
  const [yil, setYil] = useState(0);
  const [months,setMonths] = useState(["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık"]);
  const [events, setEvents] = useState([]);
  const [teams, setteams] = useState([]);
  const [team, setteam] = useState("0");
  const [selected_event, setSelected_event] = useState(0);

  const changeEvent = (e) => { setSelected_event(e.target.value) }
  const changeteam = (e) => { setteam(e.target.value) }

  useEffect(() => {
    getPlayerDetails()
  }, [])
  
  const getPlayerDetails = () => {
    axios.get(process.env.REACT_APP_API_URL+'admin_player/'+playerId).then(function (response) {
      console.log(response.data.player);

      if(response.data.result === "Success"){
        setName(response.data.player.name)
        setEmail(response.data.player.email)
        setPhone(response.data.player.phone)
        setNickname(response.data.player.nickname)
        setInstagram(response.data.player.instagram)
        const valentines = new Date(response.data.player.dtar);
        const year = valentines.getFullYear();
        const mo = valentines.getMonth()+1;
        const day = valentines.getDate();

        setGun(day)
        setAy(mo)
        setYil(year)
        //setSelected_event(response.data.player.stage)
        setteam(response.data.player.team)


      }
    });
  }

  



  const handleName = (e) => {
    setName(e.target.value)
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handlePhone = (e) => {
    setPhone(e.target.value)
  }
  const handleNickname = (e) => {
    setNickname(e.target.value)
  }
  const handleInstagram = (e) => { setInstagram(e.target.value) }
  const changeGun = (e) => { setGun(e.target.value) }
  const changeAy = (e) => { setAy(e.target.value) }
  const changeYil = (e) => { setYil(e.target.value) }



  const save = () => {
    let _ready = true

    if(name === ""){_ready = false; showError("Lütfen isim giriniz");}
    if(nickname.length <= 2){_ready = false; showError("Lütfen nickname giriniz");}
    if(email === ""){_ready = false; showError("Lütfen email adresi giriniz");}
    if(phone === ""){_ready = false; showError("Lütfen telefon numarası giriniz");}

    if(_ready){
      let data = {
        token : localStorage.getItem('otesi_admin_token'),
        playerId : playerId,
        name : name,
        nickname : nickname,
        email : email,
        phone : phone,
        instagram : instagram,
        gun : gun,
        ay :ay,
        yil : yil,
        team : team,
      };
      
      axios.post(process.env.REACT_APP_API_URL+'admin_update_player',JSON.stringify(data)).then(function (response) {

          console.log(response.data)
              
              if(response.data.result === 'Success'){
    
                showSuccess("Oyuncu başarıyla kaydedildi!");
                navigate("/admin_otesi/players/"+stageId)
    
              }

      })
      
    }

  }

  
  useEffect(() => {
    //getEvents()
    getTeams()
  }, [])
  
  const getEvents = () => {
    axios.get(process.env.REACT_APP_API_URL+'web_stages').then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
        setEvents(response.data.stages)
      }
    });
  }
  
  const getTeams = () => {
    axios.get(process.env.REACT_APP_API_URL+'admin_teams').then(function (response) {
      if(response.data.result === "Success"){
        setteams(response.data.teams)
      }
    });
  }
  


    return (
      <>
      <div>
        <AdminLeft page={"stages"} />

        <div className="lg:pl-72">

          <AdminHeader />

          <main className="p-8 ">
            <div className="  flex">
              <div className='font-medium text-3xl flex-1'>Oyuncu Düzenle</div>
              
            </div>
            
            <div className='mt-10'>
              <label className="block font-medium leading-6 text-gray-900">Ad, Soyad</label>
              <div className="mt-2">
                <input type="text" value={name} onChange={handleName}
                  className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
              </div>
            </div>
            <div className='mt-4'>
              <label  className="block font-medium leading-6 text-gray-900">Email Adresi</label>
              <div className="mt-2">
                <input type="text" value={email} onChange={handleEmail}
                  className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
              </div>
            </div>
            <div className='mt-4'>
              <label  className="block font-medium leading-6 text-gray-900">Telefon</label>
              <div className="mt-2">
                <input type="text" value={phone} onChange={handlePhone} placeholder='5XX XXX-XX-XX'
                  className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
              </div>
            </div>
            <div className='mt-4'>
              <label  className="block font-medium leading-6 text-gray-900">Nickname</label>
              <div className="mt-2">
                <input type="text" value={nickname} onChange={handleNickname} placeholder=''
                  className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
              </div>
            </div>
            <div className='mt-4'>
              <label  className="block font-medium leading-6 text-gray-900">Instagram</label>
              <div className="mt-2">
                <input type="text" value={instagram} onChange={handleInstagram} placeholder=''
                  className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
              </div>
            </div>

            <div className='mt-4'>
              <label  className="block font-medium ">Doğum Tarihi</label>
              <div className="mt-1 flex space-x-4">
                <select value={gun} onChange={changeGun} className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                  {[...Array(31).keys()].map((i,index) => (
                    <option key={i+1} value={i+1} className='text-slate-900'>{i+1}</option>
                  ))}
                </select>

                <select value={ay} onChange={changeAy} className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                  {months.map((val,index) => (
                    <option key={index+1} value={index+1} className='text-slate-900'>{val}</option>
                  ))}
                </select>

                <select value={yil} onChange={changeYil} className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                  <option key={0} value={"Yıl"} className='text-slate-900'>Yıl</option>
                  {[...Array(55).keys()].map((i,index) => (
                    <option key={i} value={i+1970} className='text-slate-900'>{i+1970}</option>
                  ))}
                </select>
              </div>
            </div>

            
            <div className='mt-4'>
              <label  className="block font-medium ">Takım</label>
              <div className="mt-2 ">

                <select value={team} onChange={changeteam} className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
              <option key={0} value={"0"} className='text-slate-900'>{"Yok"}</option>
                  {teams.map((val,index) => (
                    <option key={index+1} value={val.id} className='text-slate-900'>{val.name}</option>
                  ))}
                </select>
              </div>
            </div>


            

            <div className='mt-6'>
              <button onClick={save}
              className=" rounded-md bg-slate-800 px-10 py-4  font-semibold leading-6 text-white shadow-sm hover:bg-purple-700 " >
                Kaydet
              </button>
            </div>

          </main>

        </div>
      </div>


      </>
    );
}

  export default AdminPlayerEdit;