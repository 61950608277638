
import {  useState , useEffect, useRef} from 'react'
import axios from 'axios'
import Modal from 'react-modal';

import {showError, showSuccess,date_tr} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import levelup_logo_main from '../assets/images/levelup_logo_main.png'


function Qr001() {
  const navigate = useNavigate();
  const [stages, setstages] = useState([]);

  




  

    return (
      <div className='p-10'>

        <a target='_blank' href='WIFI:S:TurkTelekom_ZY7V3;T:WPA;P:bCfAcBa446dB0;H:false;' className='underline p-4'>XXXXXXX</a>

        <a target='_blank' href="wifi://:bCfAcBa446dB0@TurkTelekom_ZY7V3" className='underline p-4'>YYYYY</a>

      </div>
    );
}

  export default Qr001;