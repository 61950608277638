
import {  useState , useEffect} from 'react'
import axios from 'axios'
import {date_tr} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import Footer from '../components/Footer'
import levelup_logo_main from '../assets/images/levelup_logo_main.png'

function Member() {
  const navigate = useNavigate();

  const [player, setplayer] = useState({});
  const [stages, setstages] = useState([]);
  const [position, setposition] = useState("0");

  
  const goToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    getPlayer()
  }, [])
  
  const getPlayer = () => {

    let token = localStorage.getItem('otesi_token');

    let data = { token : token };
    if(token === "" || token === null){
      navigate('/')
    }

    
    axios.post(process.env.REACT_APP_API_URL+'web_check_player',JSON.stringify(data)).then(function (response) {
      console.log(response.data);
      if(response.data.result === "Success"){
        setplayer(response.data.player)
        setstages(response.data.stages)
        //setposition((response.data.position+''))
      }else{
        navigate('/')
      }
    });
    
  }
  

  const cikis = () => {
    localStorage.removeItem('otesi_token')
    navigate('/')
  }
  const ceversn = (gelen) => {

    var decimal = gelen - Math.floor(gelen)
    var basi = Math.floor(gelen - decimal)


    let dk = Math.floor(basi / 60)
    let sn = basi - (dk * 60)
    return String(dk).padStart(2, '0') + " : " + String(sn).padStart(2, '0') + " : " + String(Math.round(decimal * 1000)).padStart(3, '0') 

  }


    return (
      <>
      <div className='web_bg min-h-screen'>
          
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>

            <div className='pt-4'><img onClick={goToHome} src={levelup_logo_main} className='w-40 cursor-pointer' alt='' /></div>

            <div className="mt-10 lg:w-[36rem] w-full mx-auto" >
              <div className='text-2xl font-semibold	'>Merhaba {player?.name},</div>
              <div className='text-lg font-semibold mt-6	'>Rezervasyon Detayları</div>
              <div className='  mt-2'>Kayıt olurken seçtiğin etkinlik detaylarını aşağıda bulabilirsin. </div>
              <div className='mt-1 mb-8 text-yellow-100  '>Etkinlik tarihinde standımıza bekliyoruz, şimdiden başarılar... </div>

              {stages.map((val,index) => (

                <div className='mt-4' key={index}>
                  <div className='border border-white p-3 grid grid-cols-3 rounded-xl gap-1 bg_stage_alt'>
                    <div className='col-span-1'>Etkinlik Yeri:</div>
                    <div className='col-span-2'>{val?.stage_details?.name}</div>
                    <div className='col-span-1'>Tarih:</div>
                    <div className='col-span-2'>{date_tr(val?.stage_details?.dates)}</div>
                    <div className='col-span-1'>Saat Aralığı:</div>
                    <div className='col-span-2'>{val?.stage_details?.hours}</div>
                    <div className='col-span-1'>Adres:</div>
                    <div className='col-span-2'>{val?.stage_details?.address}</div>
                    <div className='col-span-1 mt-5'>Toplam Süre:</div>
                    <div className='col-span-2 mt-5'>{val?.toplam === "0" ? "YOK" : ceversn(val?.toplam)}</div>
                    <div className='col-span-1'>Etap Sırası:</div>
                    <div className='col-span-2'>{val?.sira === 0 ? "YOK" : val?.sira}</div>
                  </div>
                </div>
                
              ))}



              <div className='mt-20 font-semibold text-2xl'>Nasıl katılacağım?</div>
              <div className='mt-1'>1. Kayıt olurken seçtiğin etkinlik yerinde, belirtilen tarih ve saat aralığında standımıza gel.</div>
              <div>2. 14:00 - 15:00 saatleri arasında check-in yaptır.</div>
              <div>3. Kendini tanıt ve sırasıyla F1 23 ve Fortnite oyunlarını oyna.</div>
              <div>4. Toplam puanın ile diğer katılımcılarla yarış.</div>


              <div className='mt-20 text-center'>Rezervasyonunu değiştirmek için bize ulaşabilirsin.</div>
              <div className='mt-1 text-center te_m'>otesinegec@outlook.com</div>
              <div className='mt-10 text-center'>
                <button onClick={cikis} className='text-white bg-blue-950 rounded-lg px-4 py-2'>Çıkış</button>
              </div>
            </div>





            <Footer />

          </div>



      </div>
      
      </>
    );
}

  export default Member;