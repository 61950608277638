
import {  useState , useEffect} from 'react'
import axios from 'axios'

import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {showError, showSuccess} from '../components/commonFuncs'
import { useNavigate,} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




function AdminStagesAdd() {
  const navigate = useNavigate();
  
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [hours, setHours] = useState("");
  const [startDate, setStartDate] = useState(new Date());


  const handleName = (e) => {
    setName(e.target.value)
  }
  const handleAddress = (e) => {
    setAddress(e.target.value)
  }
  const handleHours = (e) => {
    setHours(e.target.value)
  }




  const goToStageAdd = () => {
    
  }

  const add = () => {

    let _ready = true
    if(name === ""){_ready = false; showError("Lütfen etap ismi giriniz.");}
    if(address === ""){_ready = false; showError("Lütfen adres giriniz.");}
    if(hours === ""){_ready = false; showError("Lütfen saat aralığı giriniz.");}

    if(_ready){

      let data = {
        token : localStorage.getItem('otesi_admin_token'),
        name : name,
        address : address,
        hours : hours,
        startDate : startDate,
      };

      axios.post(process.env.REACT_APP_API_URL+'admin_add_stage',JSON.stringify(data))
        .then(function (response) {

          console.log(response.data)
              
              if(response.data.result === 'Success'){
    
                showSuccess("Etap başarıyla eklendi!");
                navigate('/admin_otesi/stages')
              }else{
                showError("Bir hata oluştu!");
              }

        });

      



    }

  }


    return (
      <>
      <div>
        <AdminLeft page={"stages"} />

        <div className="lg:pl-72">

          <AdminHeader />

          <main className="p-8 ">
            <div className="  flex">
              <div className='font-medium text-3xl flex-1'>Yeni Etap Ekle</div>
              
            </div>
            
            <div className='mt-10'>
              <label className="block font-medium leading-6 text-gray-900">Etap Adı</label>
              <div className="mt-2">
                <input type="text" value={name} onChange={handleName}
                  className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
              </div>
            </div>
            <div className='mt-4'>
              <label  className="block font-medium leading-6 text-gray-900">Adres</label>
              <div className="mt-2">
                <input type="text" value={address} onChange={handleAddress}
                  className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
              </div>
            </div>
            <div className='mt-4'>
              <label  className="block font-medium leading-6 text-gray-900">Saat Aralığı</label>
              <div className="mt-2">
                <input type="text" value={hours} onChange={handleHours} placeholder='14:00 - 18:00'
                  className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
              </div>
            </div>
            <div className='mt-4'>
              <label  className="block font-medium leading-6 text-gray-900">Etap Tarihi</label>
              <div className="mt-2">
              <DatePicker className='border border-slate-200 py-3 px-4  rounded-md' selected={startDate} onChange={(date) => setStartDate(date)} />

              </div>
            </div>

            <div className='mt-6'>
              <button onClick={add}
              className=" rounded-md bg-slate-800 px-10 py-4  font-semibold leading-6 text-white shadow-sm hover:bg-purple-700 " >
                Ekle
              </button>
            </div>

          </main>

        </div>
      </div>


      </>
    );
}

  export default AdminStagesAdd;