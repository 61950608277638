
import {  useState , useEffect, } from 'react'
import axios from 'axios'
import {Routes, Route, useNavigate, useParams } from 'react-router-dom';

import AdminLeft from '../components/Admin_Left'
import AdminHeader from '../components/Admin_Header'
import {showError, showSuccess} from '../components/commonFuncs'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




function AdminPlayerPuanEdit() {
  const navigate = useNavigate();
  let { id } = useParams();
  let { stageId } = useParams();

  const [playerId, setplayerId] = useState("");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [f1_1, setf1_1] = useState(0);
  const [f1_2, setf1_2] = useState(0);
  const [f1_3, setf1_3] = useState(0);
  const [fornite_1, setfornite_1] = useState(0);
  const [fornite_2, setfornite_2] = useState(0);
  const [fornite_3, setfornite_3] = useState(0);
  const [toplam, setToplam] = useState(0);




  useEffect(() => {

    
    var f1_ek1 = 0;
    var f1_ek2 = 0;
    if(parseInt(f1_2)  > parseInt(f1_1)){f1_ek1 = 60}
    if(parseInt(f1_3) > parseInt(f1_2)){f1_ek2 = 60}
    var f1_toplam = parseInt(f1_1) + parseInt(f1_2) + parseInt(f1_3) + f1_ek1 + f1_ek2;

    var fort1_ek1 = 0;
    var fort1_ek2 = 0;
    if(parseInt(fornite_2) > parseInt(fornite_1)){fort1_ek1 = 60}
    if(parseInt(fornite_3) > parseInt(fornite_2)){fort1_ek2 = 60}
    var fort_toplam = parseInt(fornite_1) + parseInt(fornite_2) + parseInt(fornite_3) + fort1_ek1 + fort1_ek2;
    
    setToplam(f1_toplam +  fort_toplam)
    
  }, [f1_1,f1_2,f1_3,fornite_1,fornite_2,fornite_3])
  


  useEffect(() => {
    getPlayerDetails()
  }, [])


  const [f1_1_dk, setf1_1_dk] = useState(0);
  const [f1_1_sn, setf1_1_sn] = useState(0);
  const [f1_1_msn, setf1_1_msn] = useState(0);
  const [f1_2_dk, setf1_2_dk] = useState(0);
  const [f1_2_sn, setf1_2_sn] = useState(0);
  const [f1_2_msn, setf1_2_msn] = useState(0);
  const [f1_3_dk, setf1_3_dk] = useState(0);
  const [f1_3_sn, setf1_3_sn] = useState(0);
  const [f1_3_msn, setf1_3_msn] = useState(0);

  const [fornite_1_dk, setfornite_1_dk] = useState(0);
  const [fornite_1_sn, setfornite_1_sn] = useState(0);
  const [fornite_2_dk, setfornite_2_dk] = useState(0);
  const [fornite_2_sn, setfornite_2_sn] = useState(0);
  const [fornite_3_dk, setfornite_3_dk] = useState(0);
  const [fornite_3_sn, setfornite_3_sn] = useState(0);
  
  const getPlayerDetails = () => {
    axios.get(process.env.REACT_APP_API_URL+'admin_player_points/'+id).then(function (response) {
      console.log(response.data.player);

      if(response.data.result === "Success"){
        setName(response.data.player.name)
        setNickname(response.data.player.nickname)
        setplayerId(response.data.player.id)

        setToplam(response.data.points.toplam)
        setf1_1_dk(response.data.points.f1_1_dk)
        setf1_1_sn(response.data.points.f1_1_sn)
        setf1_1_msn(response.data.points.f1_1_msn)

        setf1_2_dk(response.data.points.f1_2_dk)
        setf1_2_sn(response.data.points.f1_2_sn)
        setf1_2_msn(response.data.points.f1_2_msn)

        setf1_3_dk(response.data.points.f1_3_dk)
        setf1_3_sn(response.data.points.f1_3_sn)
        setf1_3_msn(response.data.points.f1_3_msn)

        setfornite_1_dk(response.data.points.fornite_1_dk)
        setfornite_1_sn(response.data.points.fornite_1_sn)
        setfornite_2_dk(response.data.points.fornite_2_dk)
        setfornite_2_sn(response.data.points.fornite_2_sn)
        setfornite_3_dk(response.data.points.fornite_3_dk)
        setfornite_3_sn(response.data.points.fornite_3_sn)


      }
    });
  }


  const save = () => {

    var f1_ek1 = 0;
    var f1_ek2 = 0;

    let f1_1_toplam = f1_1_dk*60 + parseInt(f1_1_sn) +  (f1_1_msn/1000)
    let f1_2_toplam = f1_2_dk*60 + parseInt(f1_2_sn) +  (f1_2_msn/1000)
    let f1_3_toplam = f1_3_dk*60 + parseInt(f1_3_sn) +  (f1_3_msn/1000)

    if(f1_2_toplam  > f1_1_toplam){f1_ek1 = 3}
    if(f1_3_toplam  > f1_2_toplam){f1_ek2 = 3}

    let f1_toplam =  f1_1_toplam + f1_2_toplam + f1_3_toplam + f1_ek1 + f1_ek2;

    console.log(f1_toplam);

    var fornite_ek1 = 0;
    var fornite_ek2 = 0;

    let fornite_1_toplam = fornite_1_dk*60 + parseInt(fornite_1_sn)
    let fornite_2_toplam = fornite_2_dk*60 + parseInt(fornite_2_sn)
    let fornite_3_toplam = fornite_3_dk*60 + parseInt(fornite_3_sn)

    if(fornite_2_toplam  > fornite_1_toplam){fornite_ek1 = 3}
    if(fornite_3_toplam  > fornite_2_toplam){fornite_ek2 = 3}

    let fornite_toplam =  fornite_1_toplam + fornite_2_toplam + fornite_3_toplam + fornite_ek1 + fornite_ek2;

    console.log(fornite_toplam);

    let toplam = fornite_toplam +f1_toplam

    
      let data = {
        token : localStorage.getItem('otesi_admin_token'),
        playerId : playerId,
        stageId : stageId,

        f1_1_dk : f1_1_dk,
        f1_1_sn : f1_1_sn,
        f1_1_msn : f1_1_msn,

        f1_2_dk : f1_2_dk,
        f1_2_sn : f1_2_sn,
        f1_2_msn : f1_2_msn,
        
        f1_3_dk : f1_3_dk,
        f1_3_sn : f1_3_sn,
        f1_3_msn : f1_3_msn,

        f1_1 : f1_1_toplam,
        f1_2 : f1_2_toplam,
        f1_3 : f1_3_toplam,

        fornite_1_dk : fornite_1_dk,
        fornite_2_dk : fornite_2_dk,
        fornite_3_dk : fornite_3_dk,

        fornite_1_sn : fornite_1_sn,
        fornite_2_sn : fornite_2_sn,
        fornite_3_sn : fornite_3_sn,

        fornite_1 : fornite_1_toplam,
        fornite_2 : fornite_2_toplam,
        fornite_3 : fornite_3_toplam,

        toplam : toplam,
      };
      
      axios.post(process.env.REACT_APP_API_URL+'admin_update_player_puan',JSON.stringify(data)).then(function (response) {

          console.log(response.data)
              
              if(response.data.result === 'Success'){
    
                showSuccess("Oyuncu puanı başarıyla kaydedildi!");
                navigate("/admin_otesi/players/"+stageId)
    
              }

      })
      
    

  }

  
 


    return (
      <>
      <div>
        <AdminLeft page={"stages"} />

        <div className="lg:pl-72">

          <AdminHeader />

          <main className="p-8 ">
          <div className='font-medium text-3xl '>{name} - {nickname}</div>
            <div className='font-medium text-xl'>Puan Düzenle</div>
            

            <div className='grid grid-cols-2'>
              <div>
                <div className='mt-10 font-semibold'>F1 ETAP 1</div>
                <div className='flex space-x-4 w-96'>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Dakika</label>
                    <div className="mt-1">
                      <input type="number" value={f1_1_dk} onChange={(e) => setf1_1_dk(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Saniye</label>
                    <div className="mt-1">
                      <input type="number" value={f1_1_sn} onChange={(e) => setf1_1_sn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Milisaniye</label>
                    <div className="mt-1">
                      <input type="number" value={f1_1_msn} onChange={(e) => setf1_1_msn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                </div>

                <div className='mt-10 font-semibold'>F1 ETAP 2</div>
                <div className='flex space-x-4 w-96'>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Dakika</label>
                    <div className="mt-1">
                      <input type="number" value={f1_2_dk} onChange={(e) => setf1_2_dk(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Saniye</label>
                    <div className="mt-1">
                      <input type="number" value={f1_2_sn} onChange={(e) => setf1_2_sn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Milisaniye</label>
                    <div className="mt-1">
                      <input type="number" value={f1_2_msn} onChange={(e) => setf1_2_msn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                </div>

                <div className='mt-10 font-semibold'>F1 ETAP 3</div>
                <div className='flex space-x-4 w-96'>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Dakika</label>
                    <div className="mt-1">
                      <input type="number" value={f1_3_dk} onChange={(e) => setf1_3_dk(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Saniye</label>
                    <div className="mt-1">
                      <input type="number" value={f1_3_sn} onChange={(e) => setf1_3_sn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Milisaniye</label>
                    <div className="mt-1">
                      <input type="number" value={f1_3_msn} onChange={(e) => setf1_3_msn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                </div>

              </div>
              <div>

                <div className='mt-10 font-semibold'>Fornite  ETAP 1</div>
                <div className='flex space-x-4 w-96'>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Dakika</label>
                    <div className="mt-1">
                      <input type="number" value={fornite_1_dk} onChange={(e) => setfornite_1_dk(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Saniye</label>
                    <div className="mt-1">
                      <input type="number" value={fornite_1_sn} onChange={(e) => setfornite_1_sn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                </div>

                <div className='mt-10 font-semibold'>Fornite  ETAP 2</div>
                <div className='flex space-x-4 w-96'>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Dakika</label>
                    <div className="mt-1">
                      <input type="number" value={fornite_2_dk} onChange={(e) => setfornite_2_dk(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Saniye</label>
                    <div className="mt-1">
                      <input type="number" value={fornite_2_sn} onChange={(e) => setfornite_2_sn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                </div>


                <div className='mt-10 font-semibold'>Fornite  ETAP 3</div>
                <div className='flex space-x-4 w-96'>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Dakika</label>
                    <div className="mt-1">
                      <input type="number" value={fornite_3_dk} onChange={(e) => setfornite_3_dk(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className="block font-medium leading-6 text-gray-900">Saniye</label>
                    <div className="mt-1">
                      <input type="number" value={fornite_3_sn} onChange={(e) => setfornite_3_sn(e.target.value)}
                        className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"/>
                    </div>
                  </div>
                </div>




              </div>
            </div>






            

            

            <div className='mt-10 text-xl font-semibold'>
              Toplam Süre : {toplam}
            </div>
            
            


            

            <div className='mt-10'>
              <button onClick={save}
              className=" rounded-md bg-slate-800 px-10 py-4  font-semibold leading-6 text-white shadow-sm hover:bg-purple-700 " >
                Kaydet
              </button>
            </div>

          </main>

        </div>
      </div>


      </>
    );
}

  export default AdminPlayerPuanEdit;