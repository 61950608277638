
import {  useState , useEffect, useRef} from 'react'
import axios from 'axios'
import { useMask } from '@react-input/mask';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import Modal from 'react-modal';
import { TbThumbUp } from "react-icons/tb";


import levelup_logo_main from '../assets/images/levelup_logo_main.png'




function RegisterSuccess() {
  const navigate = useNavigate();

  

  const goToHome = () => {
    navigate('/')
  }

  const save = () => {
    navigate('/login')
  }


  


    return (
      <>
      <div className='web_bg min-h-screen'>
          
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>

            <div className='pt-4'><img onClick={goToHome} src={levelup_logo_main} className='w-40 cursor-pointer' alt='' /></div>

            <div className='mt-10 place-items-center flex flex-col lg:w-[36rem] w-full mx-auto'>
              <div className='text-center text-4xl tracking-widest mb-4	'>KAYIT ALINDI</div>
              <TbThumbUp size={100} strokeWidth={1} />
              <div className='text-center  mt-6'>Seçtiğin etkinlikler için ön kaydın başarıyla alındı.</div>
              <div className='text-center  mt-2'>Unutma! Her etkinlikte sınırlı sayıda oyuncuyu kabul edebiliyoruz.</div>
              <div className='text-center  mt-2'>Bu nedenle 14:00 - 15:00 arasında seçtiğin günde etkinlik noktasına gelip check-in yaptırarak kaydını tamamlamalısın.</div>
              <div className='text-center text-slate-500 mt-10'>Sınırlı etkinlik süresi ve cihaz sayısı sebebiyle ön kayıt yaptıran tüm oyuncularımıza katılım şansı veremeyebiliriz. Anlayışınız için teşekkür ederiz.</div>
            </div>

            <div className="mt-8 lg:w-[36rem] w-full mx-auto" >
              <button onClick={save}
              className="flex w-full justify-center rounded-xl pink_button px-3 py-4  font-semibold leading-6 text-white shadow-sm " >
                GİRİŞ
              </button>
            </div>



            

          </div>



      </div>
      
      </>
    );
}

  export default RegisterSuccess;