
import {  useState , useEffect, Fragment} from 'react'
import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import {
    Squares2X2Icon,
    EnvelopeIcon,
    UserGroupIcon,
    ViewfinderCircleIcon,
    Cog6ToothIcon,
    HomeIcon,
    XMarkIcon,
    
} from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import levelup_panel_logo from'../assets/images/levelup_panel_logo.png';

function AdminLeft({page}) {
    const navigate = useNavigate();


    const navigation = [
        { name: 'Dashboard', href: '/admin_otesi/dashboard', icon: HomeIcon, current: page === "dashboard" ? true : false },
        { name: 'Etaplar', href: '/admin_otesi/stages', icon: Squares2X2Icon, current:  page === "stages" ? true : false },
        { name: 'Takımlar', href: '/admin_otesi/teams', icon: UserGroupIcon, current: page === "teams" ? true : false },
        { name: 'Starwars Kodları ', href: '/admin_otesi/starwars_codes', icon: ViewfinderCircleIcon, current: page === "starwars_codes" ? true : false },
        { name: 'Giden Emailler', href: '/admin_otesi/email_list', icon: EnvelopeIcon, current: page === "email_list" ? true : false },
    ]



    function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
    }
    const [sidebarOpen, setSidebarOpen] = useState(false)
    
    
return (
    <>

        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
                <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
                >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                        <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=white"
                        alt="Your Company"
                        />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                                <li key={item.name}>
                                <a
                                    href={item.href}
                                    className={classNames(
                                    item.current
                                        ? 'bg-indigo-700 text-white'
                                        : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                >
                                    <item.icon
                                    className={classNames(
                                        item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                                        'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                    />
                                    {item.name}
                                </a>
                                </li>
                            ))}
                            </ul>
                        </li>

                        <li className="mt-auto">
                            <a
                            href="#"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
                            >
                            <Cog6ToothIcon
                                className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                aria-hidden="true"
                            />
                            Settings
                            </a>
                        </li>
                        </ul>
                    </nav>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-slate-50  border-r border-gray-200 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center m-10">
                <img className="w-10 mx-auto" src={levelup_panel_logo} alt="" />
            </div>
            <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                    <ul role="list" className="mx-1 space-y-3">
                    {navigation.map((item) => (
                        <li key={item.name}>
                        <a
                            href={item.href}
                            className={classNames(
                            item.current
                                ? 'bg-slate-800 text-white'
                                : 'text-slate-900 hover:text-white hover:bg-slate-800',
                            'group flex gap-x-3 rounded-md p-2  leading-6 font-semibold'
                            )}
                        >
                            <item.icon
                            className={classNames(
                                item.current ? 'text-white' : 'text-slate-900 group-hover:text-white',
                                'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                            />
                            {item.name}
                        </a>
                        </li>
                    ))}
                    </ul>
                </li>
                
                
                </ul>
            </nav>
            </div>
        </div>


    </>
    );
}

export default AdminLeft;